<template>
  <div class="green-section--mob">
    <GreenBlock>
      <v-container>
        <h1 class="page-title">Willkommen zum eprimo SmartMeter Angebot</h1>
        <div class="green-section__content">
          <div class="green-section--left">
            <div class="eprimo-smile-img">
              <img src="~@/assets/images/eprimo-face-2.svg" alt="eprimo-face">
            </div>
            <div class="page-subtitle"> Für eine korrekte Berechnung zur Nutzung Ihres Strompakets benötigen wir in
              regelmäßigen Abständen aktuelle Zählerstände. Ein SmartMeter nimmt Ihnen diese lästige Arbeit ab und liefert Daten zu Ihrer Strompaketnutzung in Echtzeit.
            </div>
            <ul class="list-checkmark mb-0">
              <li>Ablesung Ihres Zählers durch Sie entfällt</li>
              <li>Kein Termin zur Ablesung durch den Stromableser</li>
              <li>Automatische Überprüfung Ihres Strompakets</li>
              <li>regelmäßige, automatische Übersicht über Ihren Stromverbrauch</li>
              <li>zukünftig Zugriff auf neue Informationen und Funktionen</li>
            </ul>
          </div>
        </div>
      </v-container>
    </GreenBlock>
    <v-container>
      <div class="box-shadow mb-0">
        <h3 class="section__title">Bitte geben Sie Ihren persönlichen Aktionscode ein</h3>
        <p>Auf der nächsten Seite finden Sie dann Ihr persönliches SmartMeter-Angebot.</p>
        <v-form
            ref="form"
            @submit.prevent="login"
            class="form-login"
        >
          <div class="form-input-block">
            <div class="form-placeholder">E-Mail Code</div>
            <input
                class="form-input" :class="{'form-input--error': error || errorProp}"
                v-model="token"
                type="text"
                placeholder="E-Mail Code"
                required
            >
            <p v-if="error || errorProp" class="form-error">
              Ungültiger Aktionscode. Bitte nutzen Sie richtigen Aktionscode aus der letzten E-Mail, die wir Ihnen dazu gesendet haben.
            </p>
          </div>
          <div class="text-center-mobile">
            <button
                class="btn"
                type="submit"
            >
              Weiter zum Angebot
            </button>
          </div>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>

import GreenBlock from "@/components/GreenBlock";

export default {
  name: 'LoginPage',
  components: {
    GreenBlock
  },
  props: ['errorProp', 'uuidProp'],
  data() {
    return {
      token: null,
      error: false
    }
  },
  methods: {
    login() {
      fetch(process.env.VUE_APP_BACKEND_BASE_URL + 'meter_order/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: this.token
        })
      })
          .then(res => res.json())
          .then(data => {
            if (data.success) {
              this.$router.push({name: 'meter_ordering_form', params: {token: this.token, loggedIn: true, data: data.user}});
            } else {
              if (data.errorCode === 0) {
                this.error = true;
              } else {
                this.$router.push({name: 'meter_ordering_error'});
              }
            }
          })
    }
  },
  mounted() {
    if (this.uuidProp) {
      this.token = this.uuidProp;
    }
  }
  // props: ['errorProp', 'uuidProp'],
  // data() {
  //   return {
  //     uuid: '',
  //     error: false,
  //     errorMessage: 'Ein ungültiger Aktionscode. Bitte verwenden Sie den richtigen Aktionscode.'
  //   }
  // },
  // methods: {
  //   login() {
  //     this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'user/login', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         uuid: this.uuid
  //       })
  //     })
  //         .then(res => res.json())
  //         .then(json => {
  //           if (json.success) {
  //             this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'data/output/' + this.uuid, {
  //               method: 'GET',
  //             })
  //                 .then(res => res.json())
  //                 .then(json => {
  //                   if (!json.success) {
  //                     this.$router.push({name: 'error'});
  //                   } else {
  //                     this.sendRequest(process.env.VUE_APP_BACKEND_BASE_URL + 'user/code/' + this.uuid, {
  //                       method: 'GET'
  //                     })
  //                     .then(res => res.json())
  //                     .then(json => {
  //                       if (!json.success) {
  //                         this.$router.push({name: 'expiredCode'})
  //                       } else {
  //                         this.$router.push('/' + this.uuid)
  //                       }
  //                     })
  //                   }
  //                 })
  //           } else {
  //               this.error = true;
  //             }
  //         });
  //   }
  // },
  // mounted() {
  //   if (this.uuidProp) {
  //     this.uuid = this.uuidProp;
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.green-section--right {
  margin: -40px 37px -40px 0;
}

.form {
  &-input {
    border-radius: 4px;
    height: 40px;
    width: 100%;
    display: block;
    border: 1px solid $green-3;
    padding: 6px 12px;

    &:focus {
      box-shadow: inset 1px 1px rgba(0, 0, 0, .075), 0 0 5px rgba(180, 218, 4, .5);
    }

    &-block {
      width: 100%;
      margin-right: 30px;
      margin-left: -30px;
    }

    &--error {
      border-color: $orange;
    }
  }

  &-error {
    margin-bottom: 0;
    color: $orange;
  }

  &-login {
    .btn {
      margin-top: 20px;
      margin-right: 20px;
    }
  }

  &-placeholder {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 8px;
  }
}

.box-shadow {
  padding-top: 22px;
}

input::placeholder {
  color: rgba(110, 110, 110, 0.4);
  font-size: 16px;
  font-weight: 600;
}

.v-application .v-main a, .v-application .v-main .form-error a {
  color: #FEBC02;
  text-decoration: underline;
}

.eprimo-smile-img {
  float: right;
  margin: -40px 40px 0 0;
}

.form-error {
  margin-top: 10px;
}

.green-section {
  padding: 13px 0 75px;
}

.page-subtitle {
  margin-bottom: 23px;
}

.box-shadow  p {
  margin-bottom: 10px;
}

input[type="text"] {
  font-size: 16px !important;
}

@media (max-width: 768px) {

  .form-input-block {
    margin-left: 0;
  }

  .page-subtitle {
    margin-bottom: 5px;
  }

  .green-section .list-checkmark li {
    margin-bottom: 9px;
  }

  .box-shadow {
    padding-top: 15px;

    .section__title {
      margin-bottom: 5px;
    }

    .btn {
      width: 100%;
    }
  }

  .eprimo-smile-img {
    margin: -70px 0px 10px 10px;
    position: relative;
    width: 100px;
    height: 110px;

    img {
      width: 140px;
      position: absolute;
      left: -40px;
    }
  }

  .form-error {
    font-size: 12px;

    br {
      display: none;
    }
  }

  .btn:not(.popover-email) {
    font-size: 16px;
  }

  .green-section .list-checkmark,
  .page-subtitle {
    font-size: 14px;
  }

}

@media (max-width: 768px) {
  .green-section--mob {
    padding: 0 0 20px;
    background-color: $green-1;
    background-image: -webkit-gradient(linear, left top, left bottom, from($green), to(rgba(255, 255, 255, 0)));
    background-image: -o-linear-gradient(top, $green, rgba(255, 255, 255, 0));
    background-image: linear-gradient(180deg, $green, rgba(255, 255, 255, 0));
    background-size: cover;
    background-repeat: repeat-x;
    position: relative;
    margin-bottom: 10px;

    &:before {
      content: '';
      background-image: url('~@/assets/images/bg-curve-1.png');
      height: 3vw;
      position: absolute;
      bottom: -.0625rem;
      display: block;
      width: 100%;
      background-position: top;
      background-size: cover;
      z-index: 0;
    }
  }

  .green-section--mob .green-section {
    background-image: none;
    background-color: transparent;
    margin-bottom: 10px;

    &:before {
      display: none;
    }
  }

}

@media (min-width: 768px) {
  .form {
    &-login {
      max-width: 650px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto 20px;
    }

    &-login {
      .btn {
        margin-top: 36px;
      }
    }
  }
}
</style>
